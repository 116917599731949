
// import "./app.css";
import HeaderComponent from "./components/HeaderComponent";
import NavComponent from "./components/NavComponent";
// import MainComponent from "./components/MainComponent";
import ServicesComponent from "./components/ServicesComponent";
import PortfolioComponent from "./components/PortfolioComponent";
import AboutComponent from "./components/AboutComponent";
import MentionsComponent from "./components/MentionsComponent";
import ContactComponent from "./components/ContactComponent";
import FooterComponent from "./components/FooterComponent";

import React, { useEffect } from "react";
import loadjs from "loadjs";

const App = () => {
  // hook: empty array acts as componentDidMount
  useEffect(() => {
    
    let scripts = [
      "https://www.google.com/recaptcha/api.js?&hl=pt-br",
      "https://code.jquery.com/jquery-3.6.0.min.js",
    ];
    
    loadjs(scripts, "myappscripts");
    loadjs.ready("myappscripts", function () {
      // console.log("scripts loaded");
      let jqueryDependentScripts = [
        "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js",
        
        "assets/js/counterup.min.js",
        "assets/js/inview.min.js",
        "assets/js/easypiechart.js",
        "assets/js/magnific-popup.min.js",
        "assets/js/main.js",
      ];
      loadjs(jqueryDependentScripts, "jqueryDependentScripts");
      loadjs.ready("myappscripts", function () {
        // console.log('dependents loaded');
        
      });
    });
  }, []);

  return (
    <div className="main-wrapper home-two">
      <HeaderComponent />
      <NavComponent />

      <AboutComponent />
      <ServicesComponent />
      <PortfolioComponent />
      <MentionsComponent />
      <ContactComponent />
      <FooterComponent />
    </div>
  );
}

export default App;
