import React from "react";
// import "./MainComponent.css";

const ServicesComponent = () => (
  <div id="servicos" className="skill-section bg-color section-padding">
    <div className="container">
        <div className="row">
            <div className="col-sm-4">
                <div className="section-title">
                    <h1>Serviços</h1>
                </div>                            
            </div>
            <div className="col-sm-8">
                <div className="section-content">
                    <div className="text-info">
                        <h4>Ilustração profissional</h4>
                        <p>Tenho experiência na prestação de serviços dos variados na área de ilustração para garantir um resultado excelente. Confira abaixo a lista de serviços que posso oferecer a você:</p>
                        
                    </div>
                    <div className="progress-content">
                        <div className="rating-bar bar-left">
                            <label>Ilustração em Photoshop</label>
                            <span className="rating-count pull-right">90%</span>
                            <div className="skill-progress">
                                <div className="progress">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100" >
                                    </div>
                                </div>
                            </div>
                            <label>Ilustração em Corel Draw</label>
                            <span className="rating-count pull-right">100%</span>
                            <div className="skill-progress">
                                <div className="progress">
                                    <div className="progress-bar bar1" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" >
                                    </div>
                                </div>
                            </div>
                            <label>Arte-final</label>
                            <span className="rating-count pull-right">80%</span>
                            <div className="skill-progress">
                                <div className="progress">
                                    <div className="progress-bar bar2" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" >
                                    </div>
                                </div>
                            </div>
                        </div>                                
                        <div className="skill rating-bar bar-right">
                            <label>Aerografia</label>
                            <span className="rating-count pull-right">60%</span>
                            <div className="skill-progress">
                                <div className="progress">
                                    <div className="progress-bar bar3" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" >
                                    </div>
                                </div>
                            </div>
                            <label>Guache</label>
                            <span className="rating-count pull-right">70%</span>
                            <div className="skill-progress">
                                <div className="progress">
                                    <div className="progress-bar bar4" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" >
                                    </div>
                                </div>
                            </div>
                            <label>Grafitti</label>
                            <span className="rating-count pull-right">60%</span>
                            <div className="skill-progress">
                                <div className="progress">
                                    <div className="progress-bar bar5" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" >
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>                               
                    <div className="language-skill">
                        <div className="text-info">
                            <h4>Editoração gráfica</h4>
                            <p>Compartilho minha experiência profissional para consultoria em editoração gráfica.</p>
                        </div>
                        <ul>
                            <li>
                                <h5>Design gráfico, design de impressão Fotolito</h5>
                            </li>
                            <li>
                                <h5>Padrões editoriais, Design editorial (livros, revistas, jornal)</h5>
                            </li>
                            <li>
                                <h5>Identidade visual, Identidade corporativa (branding), Logotipos</h5>
                            </li>
                            <li>
                                <h5>Design de embalagem (Packaging Design)</h5>
                            </li>                                    
                            <li>
                                <h5>Anúncios, Banners e Posters</h5>
                            </li>                                    
                            <li>
                                <h5>Tipografia, Infografia, Diagramação</h5>
                            </li>      
                            <li>
                                <h5>Painéis, Folhetos, Flyers, Outdoors</h5>
                            </li>      
                            <li>
                                <h5>Carão de visita, Mala direta</h5>
                            </li>
                            <li>
                                <h5>Ilustração para brindes personalizados (camisetas, canecas, etc)</h5>
                            </li>
                            
                            <li>
                                <h5>Ilustração em estilos variados: Cut out paper, Cartoon, Scratchboard, Splatter, Lineart, Draft, Xilo, etc</h5>
                            </li>
                        </ul>
                    </div>                             
                </div>                                             
            </div>
        </div>
    </div>
</div>
);

export default ServicesComponent;