import React from "react";
// import "./MainComponent.css";

const PortfolioComponent = () => (
  <div id="portfolio" className="portfolio-section section-padding">
    <div className="container">
        <div className="row">
            <div className="col-sm-4">
                <div className="section-title">
                    <h1>Portfólio</h1>
                </div>                             
            </div>
            <div className="col-sm-8">
                <div className="text-info">
                    <h4>Trabalhos recentes</h4>
                    <p>Tive o luxo de ilustrar para grandes clientes ao longo dos anos e tornar seus projetos realidade.</p>
                    <p>Aqui estão alguns exemplos de ilustrações com os quais me sinto orgulhoso!</p>
                </div>
                <div className="portfolio-content">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/1.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/1.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Ilustração de capa para livro infantil</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/2.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/2.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Logo para empresa de Informática</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/3.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/3.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Ilustração de livros de atividades e didáticos</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/4.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/4.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Ilustração de capa de livro infantil</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/5.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/5.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Ilustração de capa para livro infantil</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/6.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/6.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Ilustração para livro infantil</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/7.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/7.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Ilustração para livro infantil</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/8.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/8.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Ilustração para livro infantil</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-6">
                                <div className="portfolio-item">
                                    <img className="img-responsive" src="assets/images/portfolio/9.jpg" alt="Portfolio"/>
                                    <div className="portfolio-overlay">
                                        <div className="portfolio-info">
                                            <a href="assets/images/portfolio/9.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                            <h3>Ilustração para livro infantil</h3>
                                            <p>Colorido</p></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        <div className="col-sm-6">
                            <div className="portfolio-item">
                                <img className="img-responsive" src="assets/images/portfolio/10.jpg" alt="Portfolio"/>
                                <div className="portfolio-overlay">
                                    <div className="portfolio-info">
                                        <a href="assets/images/portfolio/10.jpg"><i className="fa fa-camera-retro" aria-hidden="true"></i>
                                        <h3>Ilustração para livro infantil</h3>
                                        <p>Colorido</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>
);

export default PortfolioComponent;