import React from "react";
import "./MainComponent.css";

const AboutComponent = () => (
  <div id="sobre-mim" className="about-section section-padding">
        <div className="container">
            <div className="row">
                <div className="col-sm-4">
                    <div className="section-title">
                        <h1>Wanderley Mayhé</h1>
                    </div>                            
                </div>
                <div className="col-sm-8">
                    <div className="about-info">
                        <p>Olá, sou Wanderley, ilustrador profissional brasileiro, nascido no Rio de Janeiro. Ilustro livros infantis, livros didáticos, sites e revistas. Ilustro histórias infantis, revistas recreativas e capas para revistas Xuxa, Chaves, Popeye, Mandrake, Fantasma, Recruta Zero, Riquinho, Gasparzinho, entre outros.</p>
                        <p>É um prazer compartilhar com você meu portfólio. Aproveite este momento para conferir minhas ilustrações!</p>
                        
                        <div className="signature">
                            <h1>Wanderley Mayhé</h1>
                        </div>
                    </div>
                    <address>
                        <p><span>Nome:</span> Wanderley Mayhé</p>
                        <p><span>Email:</span> wmayhe@gmail.com</p>
                        <p><span>Telefone:</span> +55 21 3039 6695</p>
                        <p><span>Endereço:</span> Rio de Janeiro, Brasil</p>
                    </address>
                    <ul className="achievement">
                        <li className="achievement-info">
                            <span className="counter">40</span>
                            <h4>Ilustrações completadas</h4>
                        </li> 
                        <li className="achievement-info">
                            <span className="counter counter1">10</span>
                            <h4>Livros ilustrados</h4>
                        </li> 
                        <li className="achievement-info">
                            <span className="counter counter2">230</span>
                            <h4>Clientes satisfeitos</h4>
                        </li>  
                    </ul>
                    <div className="about-info">
                        <br/>
                        <button type="button" className="btn btn-primary" data-toggle="collapse" data-target="#biografia">Mostrar biografia</button>
                        <div id="biografia" className="media collapse">
                            <div className="media-left">
                                <img className="img-circle media-object" src="assets/images/mencoes/wanderley-ilustrador.jpg" 
                                alt="Wanderley Ilustrador"/>
                            </div>
                            <div className="media-body">
                                <p>Wanderley Mayhé, mais conhecido como Wanderley, nascido no Rio de Janeiro, 10 de novembro de 1953, é desenhista, arte-finalista e ilustrador brasileiro.</p>
                                <p>Wanderley começou sua carreira como desenhista, entre o final dos anos 70 e no começo dos anos 80. Wanderley Mayhé fez parte da RGE – Rio Gráfica e Editora (hoje Editora Globo), onde participou da equipe brasileira de produção de capas e quadrinhos do Fantasma, Mandrake, Recruta Zero, Riquinho, Bolota, Tininha, Gasparzinho, Texas Ranger, Sítio Pica-Pau Amarelo, entre outros, sempre focando em publicações para o público infantil e infanto-juvenil.</p>
                                <p>Durante este início de carreira, Wanderley Mayhé teve contato com profissionais qualificados como Walmir Amaral, Antonino Homobono, Evaldo Oliveira, Primaggio Montovi e José Menezes.</p>
                                <p>Nos anos 90 e 2000 Wanderley trabalhou agência de publicidade no bairro da Tijuca, realizando trabalhos de diferentes estilos como aquarela, aerografia, arte-final, storyboard para diversos anúncios de jornais e revistas.</p>
                                <p>Ainda nessa época Wanderley atuou como colaborador para a Editora Globo, ilustrando e colorindo diversas revistas em quadrinhos como Xuxa, Popeye e Chaves (famoso seriado mexicano exibido na TV brasileira).</p>
                                <p>No começo do ano 2000 Wanderley ilustrou edições especiais para as revistas Coquetel, especialmente passatempos para crianças, da editora Ediouro.</p>
                                <p>Wanderley atualmente trabalha como freelancer e colaborador em diversos projetos de escritores de livros infantis.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default AboutComponent;