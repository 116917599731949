import React from "react";
// import { NavLink } from "react-router-dom";
// import NavLinksComponent from "./NavLinksComponent";
// import "./NavComponent.css";
// import $ from "jquery";

// $(".menu").on("click", function () {
//   console.log($(this).text());
// });

const NavComponent = () => {
  
  return (
    <div className="container">
      <div id="navigation" className="menu-two">
        <div className="navbar">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              <span className="sr-only">Alternar navegação</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div className="navbar-brand logo">
              <a href="/" title="Wanderley Mayhe">
                <img
                  className="img-responsive"
                  src="assets/images/logo.png"
                  alt="Ilustrador de livro infantil - Wanderley Mayhe"
                />
              </a>
            </div>
          </div>
          <div className="collapse navbar-collapse">
            <nav id="mainmenu">
              <ul className="nav navbar-nav">
                <li className=" scroll current">
                  <a href="#home-banner" className="menu">
                    Início
                  </a>
                </li>
                <li className="scroll">
                  <a href="#sobre-mim" className="menu">
                    Sobre mim
                  </a>
                </li>
                <li className="scroll">
                  <a href="#servicos" className="menu">
                    Serviços
                  </a>
                </li>
                <li className="scroll">
                  <a href="#portfolio" className="menu">
                    Portfólio
                  </a>
                </li>
                <li className="scroll">
                  <a href="#mencoes" className="menu">
                    Menções
                  </a>
                </li>
                <li className="scroll">
                  <a href="#contato" className="menu">
                    Orçamento
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavComponent;
