import React from "react";
import "./FooterComponent.css";

const FooterComponent = () => (
  <div id="footer">
    <div className="container text-center">
      <div className="footer">
        <p>
          {" "}
          &copy; <span id="year">{new Date().getFullYear()}</span>{" "}
          <strong>Wanderley Mayhé.</strong> Todos os direitos reservados
        </p>
        <p>
          <small>
            A{" "}
            <b>
              <a
                href="http://www.jusbrasil.com.br/legislacao/92175/lei-de-direitos-autorais-lei-9610-98"
                target="blank"
              >
                Lei Federal de Direitos Autorais 9610/98
              </a>
            </b>{" "}
            protege todos os desenhos, textos e criações deste site.
            <br />
            Lembre-se que a{" "}
            <a
              href="http://pt.wikipedia.org/wiki/Contrafa%C3%A7%C3%A3o"
              target="_blank"
              rel="noreferrer"
            >
              contrafração
            </a>{" "}
            (produção comercial ou reprodução não autorizada) é passível de
            indenização por perdas e danos.
            <br />
            Entre em contato antes de utilizar imagens ou frases ditas aqui para
            qualquer finalidade.
          </small>
        </p>
      </div>
    </div>
  </div>
);

export default FooterComponent;
