import React from "react";
import "./ContactComponent.css";

const ContactComponent = () => (
  <div id="contato" className="contact-section section-padding">
    <div className="container">
      <div className="row">
        <div className="col-sm-4">
          <div className="section-title">
            <h1>Peça um orçamento</h1>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="section-content">
            <div className="text-info">
              <h4>Você tem alguma dúvida?</h4>
              <p>
                Sinta-se a vontade para pedir seu orçamento sem compromisso.
              </p>
            </div>
            <div className="address">
              <ul>
                <li>
                  <div className="icons icons1">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                  </div>
                  <h3>Telefone</h3>
                  <p>+55 21 3039 6695</p>
                </li>
                <li>
                  <div className="icons icons2">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  </div>
                  <h3>Email</h3>
                  <a href="/">wmayhe@gmail.com</a>
                </li>
                <li>
                  <div className="icons icons3">
                    <i className="fa fa-external-link" aria-hidden="true"></i>
                  </div>
                  <h3>Redes sociais</h3>
                  <ul className="social list-inline">
                    <li>
                      <a
                        rel="noreferrer"
                        href="https://www.facebook.com/wanderley.mayhe.1"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        href="https://twitter.com/wanderleymayhe"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        href="https://plus.google.com/109242394430803818078?rel=author"
                        target="_blank"
                      >
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        href="https://br.linkedin.com/in/wanderleymayhe"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="contact">
              <div className="contact-info obrigado blink">
                <strong>Obrigado!</strong>
                <p>Seu pedido vai ser analisado em breve.</p>
              </div>
              <div className="contact-info falha">
                <p>
                  😞 Desculpe, ocorreu um problema ao enviar sua mensagem. Tente
                  usar sua conta de email para entrar em contato.
                </p>
              </div>
              <div className="contact-info formulario">
                <div className="title">
                  <div className="icons">
                    <i className="fa fa-commenting-o" aria-hidden="true"></i>
                  </div>
                  <h3>Peça um orçamento</h3>
                </div>
                <p>Sinta-se à vontade para enviar sua mensagem abaixo.</p>
              </div>
              
              <form id="contact-form" className="contact-form">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        id="nome"
                        name="nome"
                        type="text"
                        className="form-control"
                        required="required"
                        placeholder="Nome ou empresa"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        required="required"
                        placeholder="Seu email"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        id="telefone"
                        name="telefone"
                        type="text"
                        className="form-control"
                        required="required"
                        placeholder="Seu telefone"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <input
                        id="cidade"
                        name="cidade"
                        type="text"
                        className="form-control"
                        required="required"
                        placeholder="Sua cidade"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <select
                        id="assunto"
                        name="assunto"
                        type="text"
                        className="form-control"
                        required="required"
                        placeholder="Assunto"
                      >
                        <option value="">selecione o tipo de orçamento</option>
                        <option value="Orçamento para ilustra&ccedil;&atilde;o infantil">
                          Orçamento para ilustração infantil
                        </option>
                        <option value="Orçamento para ilustra&ccedil;&atilde;o de livros">
                          Orçamento para ilustração de livros técnicos
                        </option>
                        <option value="Orçamento para ilustra&ccedil;&atilde;o de jornal ou revista">
                          Orçamento para ilustração de jornal ou revista
                        </option>
                        <option value="Orçamento para logotipo ou design gr&aacute;fico">
                          Orçamento para logotipo ou design gráfico
                        </option>
                        <option value="Orçamento para outros servi&ccedil;os">
                          Orçamento para outros serviços
                        </option>
                        <option value="Outros assuntos">Outros assuntos</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <textarea
                        id="mensagem"
                        name="mensagem"
                        required="required"
                        className="form-control"
                        rows="7"
                        placeholder="Requisitos do trabalho"
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <div
                        className="g-recaptcha"
                        data-sitekey="6LdzQBQTAAAAAHNXBZNd4pY8ey55J30cZhCVO2fF"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="form-group pull-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    title="Enviar pedido de orçamento"
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ContactComponent;
