import React from "react";

const MentionsComponent = () => (
  <div id="mencoes" className="exprience-section bg-color section-padding">
    <div className="container">
      <div className="row">
        <div className="col-sm-4">
          <div className="section-title">
            <h1>Menções</h1>
          </div>
        </div>
        <div className="col-sm-8">
          <div className="text-info">
            <h4>O que est&atilde;o falando sobre Wanderley</h4>
          </div>
          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/aldeia.png"
                alt="Aldeia planetaria"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Aldeia Planetaria</h3>
              <h5>Entrevista</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="http://www.aldeiaplanetaria.com.br/astro-sintese/BatePapo01.htm"
                  target="_blank"
                >
                  Entrevista com Wanderley Mayhé sobre a produção de quadrinhos
                  para o personagem Fantasma (The Phantom).
                </a>
              </p>
            </div>
          </div>
          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/infoescola.png"
                alt="Infoescola"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Infoescola</h3>
              <h5>Artigo</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="https://www.infoescola.com/noticias/livro-infantil-aborda-o-problema-do-bullying-e-da-discriminacao-entre-criancas/"
                  target="_blank"
                >
                  Artigo de Helena Fazollo sobre o livro No Reino de Pirapora
                </a>
              </p>
            </div>
          </div>
          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/piraporiando.png"
                alt="Piraporiando"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Piraporiando</h3>
              <h5>Loja virtual</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="https://www.piraporiando.com/product-page/no-reino-de-pirapora"
                  target="_blank"
                >
                  Anúncio de venda do livro infantil No Reino de Pirapora com
                  ilustrações de Wanderley
                </a>
              </p>
            </div>
          </div>
          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/buscape.jpg"
                alt="Buscapé"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Buscap&eacute;</h3>
              <h5>An&uacute;ncio</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="http://www.buscape.com.br/oferta/livros/246871363/1156627"
                  target="_blank"
                >
                  Anúncio do livro didático Faz e Conta, ilustrado por Wanderley
                </a>
              </p>
            </div>
          </div>

          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/guia-quadrinhos.png"
                alt="Guia dos Quadrinhos"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Guia dos Quadrinhos</h3>
              <h5>Biografia</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="http://www.guiadosquadrinhos.com/artista/wanderley-mayhe/8575"
                  target="_blank"
                >
                  Biografia de Wanderley Mayhé contando sua trajetória
                  profissional e trabalhos realizados
                </a>
              </p>
            </div>
          </div>

          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/devoradores-livros.png"
                alt="Devoradores de livros"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Devoradores de Livros</h3>
              <h5>Quiz</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="http://devoradordelivros.com.br/livros/ver/1071"
                  target="_blank"
                >
                  Quiz sobre o livro infantil No Reino de Pirapora, ilustrado
                  por Wanderley Mayhé
                </a>
              </p>
            </div>
          </div>

          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/paixao-livros.png"
                alt="Paixão por livros"
              />
            </div>
            <div className="exprience-info">
              <h3>Blog Paix&atilde;o por Livros</h3>
              <h5>Artigo</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="http://paixaoporlivros-vick.blogspot.com.co/2015/08/o-autor-e-voce-palmira-heine.html"
                  target="_blank"
                >
                  Artigo sobre o livro infantil o Autor é Você, ilustrado por
                  Wanderley Mayhé
                </a>
              </p>
            </div>
          </div>

          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/lambiek.png"
                alt="Lambiek"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Lambiek</h3>
              <h5>Comiclopedia</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="https://www.lambiek.net/artists/m/mayhe_wanderley.htm"
                  target="_blank"
                >
                  Enciclopédia estrangeira de quadrinhos apresentando diferentes
                  estilos de ilustrações de Wanderley Mayhé
                </a>
              </p>
            </div>
          </div>

          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/blog-fantasma.jpg"
                alt="Fantasma"
              />
            </div>
            <div className="exprience-info">
              <h3>Blog Fantasma Brasil</h3>
              <h5>Artigo</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="http://fantasmabrasil.blogspot.com/2010_12_10_archive.html"
                  target="_blank"
                >
                  Menção a Wanderley, membro da equipe de ilustração do
                  personagem Fantasma (The Phantom)
                </a>
              </p>
            </div>
          </div>

          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/wikin.png"
                alt="Wikin"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Serie Wikin</h3>
              <h5>Artigo</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="http://seriewikin.serieframjandet.se/index.php/Wanderley_Mayh%C3%A9"
                  target="_blank"
                >
                  Menção a Wanderley Mayhé na enciclopédia sueca sobre
                  quadrinhos
                </a>
              </p>
            </div>
          </div>

          <hr />
          <div className="exprience">
            <div className="exprience-image">
              <img
                className="img-responsive"
                src="assets/images/mencoes/editoras.png"
                alt="Editoras"
              />
            </div>
            <div className="exprience-info">
              <h3>Site Editoras</h3>
              <h5>Artigo</h5>
              <p>
                <a
                  rel="noreferrer"
                  href="http://editoras.com/o-pontinho-desapontado/"
                  target="_blank"
                >
                  Artigo sobre o livro didático O Pontinho Desapontado ilustrado
                  por Wanderley Mayhé
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default MentionsComponent;