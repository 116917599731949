import React from "react";
// import "./HeaderComponent.css";
// import NavComponent from "./NavComponent";

const HeaderComponent = () => (
  // <header className="app-header">
  //   <NavComponent />
  //   <img src={logo} className="app-logo" alt="logo" />
  //   <p>
  //     Edit <code>src/App.js</code> and save to reload.
  //   </p>
  // </header>
  <div id="home-banner">
    <div className="container">
      <div className="row">
        <div className="col-sm-9">
          <div className="banner-content">
            <div className="home-social">
              <ul className="list-inline">
                <li>
                  <a
                    href="https://www.facebook.com/wanderley.mayhe.1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/wanderleymayhe"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://plus.google.com/109242394430803818078?rel=author"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-google-plus" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://br.linkedin.com/in/wanderleymayhe"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="banner-info">
              <h1>Olá, eu sou</h1>
              <h2>Wanderley</h2>
              <h3>ilustrador profissional</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HeaderComponent;